<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-4 pt-4">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Resgistros</h3>
              <vs-button @click="$router.push('CrearRegistros')">Registrar Movimiento</vs-button>
            </div>
          </template>
          <div class="center p-5">
            <vs-table>
              <template #header>
                <vs-input v-model="search" border placeholder="Search" />
              </template>
              <template #thead>
                <vs-tr>
                  <vs-th @click.prevent='ordenar(1)' style='max-width: 15rem; cursor: pointer;'>
                    Fecha
                  </vs-th>
                  <vs-th style='cursor: pointer;' @click.prevent='ordenar(2)'>
                    Descripcion
                  </vs-th>
                  <vs-th @click.prevent='ordenar(3)' style='cursor: pointer;'>
                    Referencia
                  </vs-th>
                  <vs-th style='cursor: pointer;' @click.prevent='ordenar(4)'>
                    Importe
                  </vs-th>
                  <vs-th style='max-width: 4rem; cursor: pointer;'>
                    Opciones
                  </vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr :key="i" v-for="(tr, i) in Registro" not-click-selected open-expand-only-td>
                  <vs-td>
                    {{ tr.fecha }}
                  </vs-td>
                  <vs-td>
                    {{ tr.descripcion }}
                  </vs-td>
                  <vs-td>
                    {{ tr.referencia }}
                  </vs-td>
                  <vs-td>
                    {{ tr.importe }}
                  </vs-td>
                  <vs-td>
                    <div class='d-flex justify-content-center' style='gap: .5rem;'>
                      <b-button class='bg-success border-0' title='Ver'
                        @click="$router.push(`DetallesRegistro/${tr.id}`)">
                        <ion-icon name="document-outline"></ion-icon>
                      </b-button>
                      <b-button class='bg-primary border-0' title='Editar'
                        @click="$router.push(`EditarRegistro/${tr.id}`)">
                        <ion-icon name="create-outline"></ion-icon>
                      </b-button>
                      <b-button class='bg-danger border-0' title='Eliminar' @click.prevent='deleteRegistro(tr.id)'>
                        <ion-icon name="trash-outline"></ion-icon>
                      </b-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
              <template #notFound>
                <div v-if='loading' class='w-100 d-flex justify-content-center align-items-center'
                  style='height: 10rem;'>
                  Buscando registros
                </div>
              </template>
              <template #footer>
                <div class='d-flex justify-content-between align-items-center'>
                  <b-form-select style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required
                    value='10'>
                  </b-form-select>
                  <vs-pagination v-model="page" :length="totalpagues" />
                </div>
              </template>
            </vs-table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
  import Swal from 'sweetalert2';
  import { core } from '../../../../../../config/pluginInit';
  // import { debounce } from 'lodash/debounce';

  export default {
    data: () => ({
      Registro: [],
      PersonaId: null,
      search: '',
      page: 1,
      totalpagues: 0,
      max: 10,
      loading: false,
      columna: 1,
      ascendente: true,
      options: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        }, {
          value: 20,
          text: '20'
        }, {
          value: 50,
          text: '50'
        }, {
          value: 100,
          text: '100'
        }
      ],
    }),
    watch: {
      // search: {
      //   handler: debounce(function () {
      //     this.getData();
      //   }, 700),
      //   immediate: false
      // },
      search() {
        this.getData();
      },
      max() {
        this.getData();
      },
      page() {
        this.getData();
      },
      totalpagues() {
        this.getData();
      },
      columna() {
        this.getData();
      },
      ascendente() {
        this.getData();
      }
    },
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('credenciales'));
      this.PersonaId = Persona.personaId;

      core.index()
      this.getData();
    },
    methods: {
      async deleteRegistro(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar este Movimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("hl_delete", {
                path: `DEMovimientos/EliminarMovimiento/${id}/persona/${this.PersonaId}`,
              });
              Swal.fire("Listo!", "Movimiento eliminado correctamente.", "success");
              await this.getData();
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar el Movimiento", "error");
            }
          }
        });
      },
      async getData() {
        let send = {
          filtro: this.search,
          orden: {
            columna: this.columna,
            ascendente: this.ascendente
          },
          cantDatos: this.max,
          paginacion: this.page
        }
        try {
          this.loading = true;
          const res = await this.$store.dispatch('hl_get', {
            path: `DEMovimientos/ConsultarCantidadMovimientos/${this.PersonaId}?filtro=${this.search}`
          })

          this.totalpagues = Math.ceil(res.data / this.max);
          if (res.data <= this.max) {
            this.page = 1;
          }

          const response = await this.$store.getters.fetchPost({
            path: 'DEMovimientos/MovimientosEmpresa/' + this.PersonaId,
            data: send
          });
          if (response.ok) {
            this.Registro = []
            const data = await response.json();
            if (data.data.length > 0) {
              this.Registro = data.data.map((item) => ({
                id: item.Id,
                fecha: this.$moment.tz(item.fecha, "America/Bogota").format("MM-DD-YYYY"),
                referencia: item.referencia,
                descripcion: item.descripcion,
                importe: item.importe,
              }))
            } else {
              this.Registro = []
            }

          } else {
            console.error('Error en la solicitud:', response.statusText);
          }
        } catch (error) {
          this.Registro = []
        } finally {
          this.loading = false;
        }
      },
      ordenar(columna) {
        if (this.columna === columna) {
          this.ascendente = !this.ascendente;
        } else {
          this.columna = columna;
          this.ascendente = true;
        }
        this.getData();
      }
    },
  }
</script>

<style>
  .header-title {
    width: 100% !important;
  }

  .vs-table__header,
  .vs-table__footer {
    background: var(--body-bg);
  }

  .vs-input {
    width: 100%;
  }
</style>